$(document).ready(function() {
    /**
     * IE support for object-fit
     */
    objectFitImages();

    /**
     * @return {Boolean} If the current view is smaller than 668px
     */
    function isMobile() {
      return window.matchMedia('(max-width: 668px)').matches;
    }

    $('#hamburger').on('click', function(event) {
        event.preventDefault();
        if ($('.navigation-wrapper').hasClass('mobile-open')) {
            $('.navigation-wrapper,.mobile-navigation').addClass('navigation-closing');

            $('.navigation-wrapper').removeClass('mobile-open');
            $('.navigation-wrapper').removeClass('navigation-closing');

            setTimeout(function() {
                $('.mobile-navigation').removeClass('mobile-open');
                $('.mobile-navigation').removeClass('navigation-closing');
            }, 200);
        } else {
            $('.navigation-wrapper,.mobile-navigation').addClass('mobile-open');
        }

        $(this).toggleClass('is-active');
    });

    $('a[href*="#"]')
      // Remove links that don't actually link to anything
      .not('[href="#"]')
      .not('[href="#0"]')
      .click(function(event) {
         $('.navigation-wrapper,.mobile-navigation').removeClass('mobile-open');
         $('#hamburger').removeClass('is-active');
        if (!$(this).hasClass('no-scroll')) {

          var target = $(this.hash);
          target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
          history.replaceState(null, null, document.location.pathname + '#' + this.hash.slice(1));

          // Does a scroll target exist?
          if (target.length) {
            // Only prevent default if animation is actually gonna happen
            event.preventDefault();
            var sc = target.offset().top;
            $('html, body').animate({
              scrollTop: sc
            }, 800, function() {
            });
          }

        }
      });
});